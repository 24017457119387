import styled from "styled-components"

export const PanelBottomWrap = styled.div`
  width: 100%;
  margin-bottom: 6rem;

  .bottom-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10%;

    .dash-line {
      border-top: 2px dashed ${(props) => props.theme.colors.green};
      flex: 0 0 20%;
    }
    .text-btn-wrap {
      flex: 0 0 60%;
      display: flex;
      justify-content: space-around;

      > h2 {
        font-size: 2rem;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .text-btn-wrap {
      text-align: center;
      flex-direction: column;
      align-items: center;

      h2 {
        margin-bottom: 1.5rem;
      }
    }
  }

  @media only screen and (max-width: 680px) {
    .bottom-inner {
      justify-content: space-between;

      .dash-line {
        flex: 0 0 10%;
      }
      .text-btn-wrap {
        flex: 0 auto;
      }
    }
  }
`
