import React, { Component, Fragment } from "react"
import { withTheme } from "styled-components"
import PanelFooter from "../PanelFooter"
import PanelHeader from "../PanelHeader"
import { master } from "../../resources/index.js"
import { FaqWrapper } from "./style"

const theFaq = master.faqInfo

class Faq extends Component {
  handleFaq = (event, eachIndex, indexOfQuestion) => {
    const { theme } = this.props
    const currentElement = event.target
    const theAnswer = document.getElementsByClassName(`answer-wrap-${eachIndex}-${indexOfQuestion}`)

    if (theAnswer[0].style.display === "" || theAnswer[0].style.display === "none") {
      theAnswer[0].style.display = "block"
      currentElement.style.color = theme.colors.red
      currentElement.lastChild.childNodes[0].innerHTML = "-"
    } else {
      theAnswer[0].style.display = "none"
      currentElement.style.color = theme.colors.black
      currentElement.lastChild.childNodes[0].innerHTML = "+"
    }
  }

  handleTabSelection = (event, type) => {
    const currentTab = event.target
    const allTabs = document.getElementsByClassName("tab")
    const allGroups = document.getElementsByClassName("body-wrap")
    const groupLookup = document.getElementsByClassName(`accordion-${type}`)

    for (let i = 0; i < allTabs.length; i++) {
      allTabs[i].classList.remove("current-tab")
    }

    for (let i = 0; i < allGroups.length; i++) {
      allGroups[i].style.display = "none"
    }
    groupLookup[0].style.display = "block"
    currentTab.classList.add("current-tab")
  }

  render() {
    return (
      <div id="faq-section">
        <PanelHeader panelTitle="FAQ" panelHeadingText="Most frequently asked questions" panelSubText="Let's find out more about FTL Leagues!" />
        <FaqWrapper>
          <div className="faq-inner">
            <div className="tabs-wrapper">
              {master.faqInfo.map((object, index) => {
                const { type } = object
                return (
                  <div
                    key={index}
                    className={`tab ${type === "basic" ? "current-tab" : ""}`}
                    onClick={(event) => this.handleTabSelection(event, type)}
                    aria-hidden="true"
                  >
                    <h3 className={`current-h3 current-h3-${type}`}>{object.tab}</h3>
                  </div>
                )
              })}
            </div>
            <div className="faq-accordion">
              {theFaq.map((each, eachIndex) => {
                const { type, accordionInfo } = each
                return (
                  <Fragment key={type}>
                    <div className={`body-wrap accordion-${type}`}>
                      {accordionInfo.map((inside, index) => {
                        const { question, answer } = inside
                        return (
                          <Fragment key={index}>
                            <div className="accordion">
                              <div
                                className={`question-wrap question-wrap-${index}`}
                                onClick={(event) => this.handleFaq(event, eachIndex, index)}
                                aria-hidden="true"
                              >
                                <p className="question">{question}</p>
                                <div className="arrow-wrap">
                                  <div className={`arrow arrow-${eachIndex}`}>+</div>
                                </div>
                              </div>
                              <div className={`answer-wrap answer-wrap-${eachIndex}-${index}`}>
                                <p className="answer">{answer}</p>
                              </div>
                            </div>
                          </Fragment>
                        )
                      })}
                    </div>
                  </Fragment>
                )
              })}
            </div>
          </div>
        </FaqWrapper>
        <PanelFooter panelFooterText="All set to join FTL community?" />
      </div>
    )
  }
}

export default withTheme(Faq)
