import React from "react"
import PanelFooter from "../PanelFooter"
import PanelHeader from "../PanelHeader"
import Theme from "../../Themes/theme"
import { WhoIsWrapper } from "./style"

const WhoIsFtlFor = () => (
  <Theme>
    <WhoIsWrapper id="what-is-ftl-section">
      <PanelHeader
        panelTitle="Who Is FTL for?"
        panelHeadingText="For Anyone Who Enjoys Playing Tennis!"
        panelSubText="We are here to connect tennis players and arrange weekly matches for you."
      />
      <div className="who-is-inner">
        <div className="who-text">
          <h3>Some of the FTL players would be:</h3>
        </div>
        <div className="who-boxes">
          <div className="who-boxes-wrapper">
            <div className="color-box blue-box">
              <div className="dash-box">
                <p>I have some experience and am looking for opportunities to match up with different types of players.</p>
              </div>
            </div>
            <div className="color-box green-box">
              <div className="dash-box">
                <p>I am looking for other tennis players who is at my skill level and near my area but finding it difficult to find them.</p>
              </div>
            </div>
            <div className="color-box red-box">
              <div className="dash-box">
                <p>I just started playing tennis and want to try out the games. I'm also looking for a tennis community to meet like-minded people!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PanelFooter panelFooterText="Does it sound like you?" />
    </WhoIsWrapper>
  </Theme>
)

export default WhoIsFtlFor
