import styled from "styled-components"

export const LeaguesInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
  margin-top: 4rem;

  .subtext-wrap {
    display: flex;
    width: 80%;
    text-align: center;
    transform: translateY(15px);

    .title-wrap {
      flex: 0 0 33.3%;
      padding: 0 4rem;

      > h3 {
        background-color: ${(props) => props.theme.colors.white};
        border: 2px solid ${(props) => props.theme.colors.darkGreen};
        box-shadow: 1px 1px 0 ${(props) => props.theme.colors.darkGree};
        text-align: center;
        padding: 0.5rem 2rem;
        font-size: 2rem;
        color: ${(props) => props.theme.colors.black};
        border-radius: 10px;
      }
    }
  }

  .box {
    width: 80%;

    .items-wrapper {
      display: flex;
      margin-bottom: 2rem;
      justify-content: space-between;

      .upper {
        text-align: center;

        .image-wrap {
          text-align: center;
          padding: 3rem 2rem 1rem 2rem;
        }

        .heading-wrap {
          display: inline-block;
          text-align: center;
          margin: 1rem 1rem 2rem;

          > h3 {
            font-size: 1.6rem;
            border-bottom: 2px dashed;
          }
        }

        .body-text-wrap {
          text-align: left;
          padding: 0 2rem;
          margin-bottom: 3rem;

          > p {
            font-size: 1.2rem;
            margin-bottom: 0.8rem;

            .singles-span {
              color: ${(props) => props.theme.colors.darkGreen};
              font-weight: 400;
            }
            .doubles-span {
              color: ${(props) => props.theme.colors.green};
              font-weight: 400;
            }
            .skill-span {
              color: ${(props) => props.theme.colors.green};
              font-weight: 400;
            }
          }
        }
      }
      .dbl-btn {
        height: 3.2rem;
        font-size: 1rem;
        padding: 0 2rem;
        display: flex;
        background-color: ${(props) => props.theme.colors.green};
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: ${(props) => props.theme.colors.white};
          border: 2px solid ${(props) => props.theme.colors.green};
          cursor: pointer;

          .dbl-btn-text {
            color: ${(props) => props.theme.colors.green};
          }
        }

        .dbl-btn-text {
          color: ${(props) => props.theme.colors.white};
          text-align: center;
          transition: all 0.2s ease-in-out;
        }
      }
      .global-box {
        flex: 0 0 32%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border: 2px solid ${(props) => props.theme.colors.dargGreen};
        border-radius: 10px;
        height: auto;
        padding-bottom: 2rem;
        position: relative;

        &:before {
          background-color: #f5f5f5;
          border: 2px solid #29596c;
          box-shadow: 1px 1px 0;
          text-align: center;
          padding: 0.5rem 2rem;
          font-size: 1.7rem;
          color: #28373e;
          border-radius: 10px;
          position: absolute;
          top: -30px;
        }
      }

      .left {
        &:before {
          content: "Types";
        }
      }
    }

    @media only screen and (max-width: 1080px) {
      width: 60%;

      .items-wrapper {
        flex-direction: column;
        height: 100%;

        .global-box {
          flex: auto;
          flex-direction: unset;
          flex-wrap: wrap;
          justify-content: space-around;
          margin-bottom: 3rem;
          padding: 2rem 0;
          width: 100%;

          &:before {
            left: 30px;
          }

          .image-wrap {
            padding: 0;

            img {
              width: 15%;
            }
          }
          .heading-wrap {
            order: -1;
            flex: 0 0 100%;
            display: flex;
            justify-content: center;

            h3 {
              width: fit-content;
            }
          }
          .body-text-wrap {
            flex: 0 0 65%;
          }
          .dbl-btn {
            flex: 0 auto;
          }
        }
      }
    }

    @media only screen and (max-width: 570px) {
      width: 90%;
    }

    .middle {
      &:before {
        content: "Skill Levels";
      }

      .yes-no-modal {
        height: 3.2rem;
        font-size: 1rem;
        padding: 0 2rem;
        display: flex;
        background-color: ${(props) => props.theme.colors.green};
        align-items: center;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: ${(props) => props.theme.colors.white};
          border: 2px solid ${(props) => props.theme.colors.green};
          cursor: pointer;

          .ynModal-btn-text {
            color: ${(props) => props.theme.colors.green};
          }
        }

        .ynModal-btn-text {
          color: ${(props) => props.theme.colors.white};
          text-align: center;
          transition: all 0.2s ease-in-out;
        }
      }

      .box-sec-wrapper {
        padding: 0 1rem;
        width: 100%;

        .color-box {
          @media only screen and (max-width: 700px) {
            width: 100%;
          }

          .dash-box {
            .box-body {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-around;
              height: 100%;

              h2 {
                margin-bottom: 3rem;
              }
              #yn-start {
                display: flex;
                justify-content: center;
                align-items: center;
                color: ${(props) => props.theme.colors.white};
                font-size: 1.2rem;
                height: 3.2rem;
                width: 6rem;
                background-color: ${(props) => props.theme.colors.green};
                transition: all 0.2s ease-in-out;

                &:hover {
                  cursor: pointer;
                  border: 2px solid ${(props) => props.theme.colors.green};
                  color: ${(props) => props.theme.colors.green};
                  background-color: ${(props) => props.theme.colors.white};
                }
              }

              > p:nth-child(1) {
                margin-bottom: 2rem;
              }
              .btns-wrap {
                display: flex;
                justify-content: space-around;
                width: 100%;
              }
            }
          }
        }
        #answer-box {
          .top-text {
            color: ${(props) => props.theme.colors.black};
            font-size: 1.2rem;
          }

          .answer-league {
            color: ${(props) => props.theme.colors.green};
            font-size: 2rem;
            margin-bottom: 2rem;
          }
          .description {
            font-size: 1.2rem;
            margin-bottom: 2rem;
          }
          #again-btn {
            background-color: ${(props) => props.theme.colors.white};
            height: 3rem;
            width: 7rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${(props) => props.theme.colors.green};
            transition: all 0.2s ease-in-out;

            p {
              color: ${(props) => props.theme.colors.green};
              font-size: 1.2rem;
            }

            &:hover {
              cursor: pointer;
              border: 2px solid ${(props) => props.theme.colors.white};
              background-color: ${(props) => props.theme.colors.green};
              color: ${(props) => props.theme.colors.white};

              p {
                color: ${(props) => props.theme.colors.white};
              }
            }
          }
          .final-btns-wrap {
            display: flex;
            justify-content: space-between;
            width: 80%;

            @media only screen and (max-width: 465px) {
              flex-direction: column;
              align-items: center;

              #again-btn {
                margin-bottom: 2rem;
              }
            }
          }
        }

        .btns-wrap {
          display: flex;
          width: 100%;
          justify-content: space-around;
          width: 90%;

          @media only screen and (max-width: 700px) {
            width: 80%;
          }
          @media only screen and (max-width: 500px) {
            width: 90%;
          }

          .yn-btn {
            height: 3.2rem;
            width: 6rem;
            font-size: 1.2rem;
            border: 2px solid ${(props) => props.theme.colors.white};
            padding: 0 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${(props) => props.theme.colors.white};
            transition: all 0.2s ease-in-out;
          }
          .yes-btn {
            background-color: ${(props) => props.theme.colors.blue};

            &:hover {
              background-color: ${(props) => props.theme.colors.white};
              color: ${(props) => props.theme.colors.blue};
              border: 4px solid ${(props) => props.theme.colors.blue};
              cursor: pointer;

              p {
                color: ${(props) => props.theme.colors.blue};
              }
            }
          }
          .no-btn {
            background-color: ${(props) => props.theme.colors.red};

            &:hover {
              background-color: ${(props) => props.theme.colors.white};
              color: ${(props) => props.theme.colors.red};
              border: 4px solid ${(props) => props.theme.colors.red};
              cursor: pointer;

              p {
                color: ${(props) => props.theme.colors.red};
              }
            }
          }
        }
      }
      .ynModal-wrap {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .right {
      &:before {
        content: "Locations";
      }

      .mapModal-btn {
        background-color: ${(props) => props.theme.colors.green};
        height: 3.2rem;
        font-size: 1rem;
        padding: 0 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: ${(props) => props.theme.colors.white};
          color: ${(props) => props.theme.colors.green};
          border: 2px solid ${(props) => props.theme.colors.green};

          .mapModal-btn-text {
            color: ${(props) => props.theme.colors.green};
            cursor: pointer;
          }
        }

        .mapModal-btn-text {
          color: ${(props) => props.theme.colors.white};
          text-align: center;
          transition: all 0.2s ease-in-out;
        }
      }
      .map-modal {
        .map-modal-inner {
          padding: 2rem;
          background-color: ${(props) => props.theme.colors.white};

          @media only screen and (max-width: 950px) {
            .australia-map {
              height: 40%;
            }
            .right-states {
              height: 60%;

              .states-ul {
                flex-wrap: wrap;

                .state-wrap {
                  flex: 0 0 50%;
                }

                @media only screen and (max-width: 465px) {
                  flex-direction: column;

                  .state-wrap {
                    flex: auto;
                    width: 100%;
                  }
                }
              }
            }
          }

          .box-wrapper-inner {
            display: flex;
            flex-direction: column;
            border: 2px dashed ${(props) => props.theme.colors.green};

            .australia-map {
              text-align: center;
              height: 200px;
            }
            .right-states {
              height: 50%;
              padding: 2rem;
              display: flex;
              justify-content: center;

              .state-lists {
                width: 90%;

                .states-ul {
                  display: flex;
                  justify-content: space-between;

                  .state-wrap {
                    list-style: none;
                    padding: 0.5rem;

                    .state-inner {
                      font-size: 1.6rem;
                    }

                    .leagues-ul {
                      padding-left: 25px;

                      > li {
                        font-size: 1rem;
                      }
                    }
                  }
                  .sawa {
                    .state-wa {
                      margin-top: 2rem;

                      .state-inside {
                        font-size: 1.6rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const Img = styled.img`
  height: 100%;
`
export const SecImg = styled.img`
  width: 40%;
`
