import React from "react"
import { LinkForBtn, Button } from "../Universal/style"
import { PanelBottomWrap } from "./style"

const PanelFooter = (props) => {
  return (
    <PanelBottomWrap>
      <div className="bottom-inner">
        <div className="dash-line"></div>
        <div className="text-btn-wrap">
          <h2>{props.panelFooterText}</h2>
          <LinkForBtn href={process.env.APP_URL} target="_blank" rel="noopener noreferrer">
            <Button>Sign Up Now!</Button>
          </LinkForBtn>
        </div>
        <div className="dash-line"></div>
      </div>
    </PanelBottomWrap>
  )
}

export default PanelFooter
