import React from "react"
import { useQuery } from "@apollo/client"

import { SeasonDates } from "../Header"
import { CURRENT_YEAR_SEASON_DATES } from "../Header/queries"

import PanelFooter from "../PanelFooter"
import PanelHeader from "../PanelHeader"
import { HowInner } from "./style"
import MiniCourt from "../Court/index"
import registerIcon from "../../resources/images/register.png"
import fixtureIcon from "../../resources/images/fixture.png"
import chatIcon from "../../resources/images/chat.png"
import playIcon from "../../resources/images/play.png"
import uploadIcon from "../../resources/images/upload.png"

const HowItWorks = () => {
  // For the season date query
  const { loading, error, data } = useQuery(CURRENT_YEAR_SEASON_DATES)

  return (
    <div id="how-it-works-section">
      <PanelHeader
        panelTitle="How It Works"
        panelHeadingText="Easy and flexible - One season is all about 5 steps"
        panelSubText="All you need to do is follow the 5 steps and enjoy the games!"
      />
      <HowInner>
        <div className="how-item-wrapper">
          <div className="how-top">
            <div className="mini-court">
              <MiniCourt
                stepNumber="#1"
                icon={registerIcon}
                secTitle="Register A Season"
                secBody="Choose a league you want to play while the registration period is open."
              />
            </div>
            <div className="mini-court">
              <MiniCourt
                stepNumber="#2"
                icon={fixtureIcon}
                secTitle="Check The Fixture"
                secBody="Your weekly match-up schedule will be generated at the beginning of the season."
              />
            </div>
          </div>
          <div className="how-middle">
            <div className="mini-court">
              <MiniCourt
                stepNumber="#3"
                icon={chatIcon}
                secTitle="Decide The Details"
                secBody="Get in touch with your opponents via our in-app messenger to discuss the details and court."
              />
            </div>
            <div className="mini-court">
              <MiniCourt
                stepNumber="#4"
                icon={playIcon}
                secTitle="Play Hard"
                secBody="Rock up at the designated time and place agreed by both players to battle it out!"
              />
            </div>
          </div>
          <div className="how-bottom">
            <div className="mini-court">
              <MiniCourt
                stepNumber="#5"
                className="mini-court"
                icon={uploadIcon}
                secTitle="Update The Score"
                secBody="Upload the game scores from your FTL account and wait for your opponent's approval or vice versa."
              />
            </div>
            <div className="notice-wrapper">
              <div className="notice-inner">
                <div className="notice-body-wrap">
                  <div className="notice-body-inner">
                    {/* For the season dates -> taken from the server of the FTL App */}
                    {loading && <p>Loading..</p>}
                    {error && <p>Current Not Available..</p>}
                    {data && <SeasonDates data={data} type={"normal"} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HowInner>
      <PanelFooter panelFooterText="Are you ready for a game?" />
    </div>
  )
}

export default HowItWorks
