import React from "react"
import styled from "styled-components"

const MiniCourtWrap = styled.div`
  .court-inner {
    display: flex;
    flex-direction: column;
    height: 20rem;
    background: ${(props) => props.theme.colors.white};

    .court-top {
      border: 3px solid ${(props) => props.theme.colors.blue};
      height: 10%;
    }

    .court-middle {
      border-right: 3px solid ${(props) => props.theme.colors.blue};
      border-left: 3px solid ${(props) => props.theme.colors.blue};
      height: 80%;
      display: flex;

      .left {
        border-right: 3px solid ${(props) => props.theme.colors.blue};
        flex: 0 0 65%;

        .text-wrap {
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;

          .section-title {
            margin: 1rem;

            > h2 {
              font-weight: 600;
              font-size: 1.6rem;
            }
          }

          .section-body {
            padding: 0 5%;
            > p {
              @media only screen and (max-width: 1100px) {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
      .right {
        flex: 0 0 35%;
        display: flex;
        flex-direction: column;

        .middle-top {
          border-bottom: 3px solid ${(props) => props.theme.colors.blue};
          height: 50%;
          flex: 0 0 50%;

          .number-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            > p {
              font-size: 4rem;
            }
          }
        }
        .middle-bottom {
          flex: 0 0 50%;
          height: 50%;

          .icon-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            .icon-circle {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: ${(props) => props.theme.colors.blue};
              width: 70px;
              height: 70px;
              border-radius: 50%;

              img {
                width: 60px;
                height: 60px;

                @media only screen and (max-width: 850px) {
                  width: 75px;
                  height: 75px;
                }
                @media only screen and (max-width: 500px) {
                  width: 65px;
                  height: 65px;
                }
              }

              @media only screen and (max-width: 850px) {
                width: 75px;
                height: 75px;
              }
              @media only screen and (max-width: 500px) {
                width: 65px;
                height: 65px;
              }
            }
          }
        }
      }
    }

    .court-bottom {
      border: 3px solid ${(props) => props.theme.colors.blue};
      height: 10%;
    }
  }
`

const Img = styled.img``

const MiniCourt = (props) => {
  return (
    <MiniCourtWrap>
      <div className="court-inner">
        <div className="court-top"></div>
        <div className="court-middle">
          <div className="left">
            <div className="text-wrap">
              <div className="section-title">
                <h2>{props.secTitle}</h2>
              </div>
              <div className="section-body">
                <p>{props.secBody}</p>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="middle-top">
              <div className="number-wrap">
                <p>{props.stepNumber}</p>
              </div>
            </div>
            <div className="middle-bottom">
              <div className="icon-wrap">
                <div className="icon-circle">
                  <Img src={props.icon} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="court-bottom"></div>
      </div>
    </MiniCourtWrap>
  )
}

export default MiniCourt
