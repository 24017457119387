import React, { Fragment, Component } from "react"
import Layout from "../components/Layout/index"
import WhoIsFtlFor from "../components/WhoIsFtlFor/index"
import HowItWorks from "../components/HowItWorks/index"
import Leagues from "../components/Leagues/index"
import Faq from "../components/Faq/index"
import Seo from "../components/Seo"

class IndexPage extends Component {
  componentDidMount() {
    const headSection = document.querySelector("head")
    const script = document.createElement("script")
    script.insertAdjacentText(
      "afterbegin",
      (function (h, e, a, t, m, p) {
        m = e.createElement(a)
        m.async = !0
        m.src = t
        p = e.getElementsByTagName(a)[0]
        p.parentNode.insertBefore(m, p)
      })(window, document, "script", "https://u.heatmap.it/log.js")
    )
    headSection.appendChild(script)
  }

  render() {
    return (
      <Fragment>
        <Seo
          title={`Australia Wide | For All Skill Levels`}
          description={`Play tennis in Australia with locals at a time, date and location that suits you. Whether you're looking to get social, active or competitive, sign up today!`}
        />
        <Layout>
          <HowItWorks />
          <Leagues />
          <WhoIsFtlFor />
          <Faq />
        </Layout>
      </Fragment>
    )
  }
}

export default IndexPage
