import styled from "styled-components"
import koalaB from "../../resources/images/koala-blue.png"
import koalaG from "../../resources/images/koala-green.png"
import koalaR from "../../resources/images/koala-red.png"

export const WhoIsWrapper = styled.div`
  .who-is-inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    .who-text {
      > h3 {
        font-size: 1.6rem;
        text-align: center;
        padding: 0 1rem;
        margin-bottom: 4rem;
      }
    }
    .who-boxes {
      width: 80%;
      margin-bottom: 4rem;

      .who-boxes-wrapper {
        display: flex;
        justify-content: space-between;
        height: 100%;

        .color-box {
          width: 30%;
          color: ${(props) => props.theme.colors.white};
          height: 25rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;

          &:after {
            content: "";
            background-color: ${(props) => props.theme.colors.white};
            border-radius: 50%;
            width: 8rem;
            height: 8rem;
            top: 5px;
            position: absolute;
            background-size: contain;
            background-size: 80%;
            background-repeat: no-repeat;
            background-position-y: center;

            @media only screen and (max-width: 1100px) {
              width: 7rem;
              height: 7rem;
            }
            @media only screen and (max-width: 990px) {
              width: 8rem;
              height: 8rem;
            }
          }

          .dash-box {
            border: 2px dashed ${(props) => props.theme.colors.white};
            width: 80%;
            height: 80%;
            padding: 35% 5%;

            p {
              font-size: 1.2rem;
              text-align: center;
            }
          }
        }
        .blue-box {
          background: ${(props) => props.theme.colors.blue};

          &:after {
            background-image: url(${koalaB});
          }
        }
        .green-box {
          background: ${(props) => props.theme.colors.green};

          &:after {
            background-image: url(${koalaG});
          }
        }
        .red-box {
          background: ${(props) => props.theme.colors.red};

          &:after {
            background-image: url(${koalaR});
          }
        }
      }
    }
  }
  @media only screen and (max-width: 990px) {
    .who-is-inner {
      .who-boxes {
        .who-boxes-wrapper {
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-around;

          .color-box {
            width: 45%;
            height: 23rem;
          }
          .red-box {
            margin-top: 2rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 700px) {
    .who-is-inner {
      .who-boxes {
        .who-boxes-wrapper {
          .color-box {
            width: 60%;
            margin-bottom: 2rem;

            .dash-box {
              padding: 25% 2%;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 650px) {
    .who-is-inner {
      .who-boxes {
        .who-boxes-wrapper {
          .color-box {
            width: 65%;
            .dash-box {
              padding: 30% 2%;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .who-is-inner {
      .who-boxes {
        .who-boxes-wrapper {
          .color-box {
            width: 100%;
          }
        }
      }
    }
  }
`
