import styled from "styled-components"
import bcgImage from "../../resources/images/how-bg.jpg"

import koalaGreen from "../../resources/images/koala-green-fill.png"

export const HowInner = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 6rem;

  .how-item-wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    position: relative;

    &:before {
      content: "";
      background-image: url(${bcgImage});
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .how-top {
      display: flex;
      z-index: 1;
      padding: 1rem 4rem;

      @media only screen and (max-width: 850px) {
        padding: 2rem;
      }
      @media only screen and (max-width: 675px) {
        flex-direction: column;
        padding: 0 2rem;
      }

      .mini-court {
        flex: 0 0 50%;

        &:first-child {
          margin-right: 0.5rem;
        }
        &:last-child {
          margin-left: 0.5rem;
        }

        @media only screen and (max-width: 675px) {
          padding-bottom: 2rem;
        }
      }

      .mini-court:nth-child(2) {
        .court-inner {
          transform: scaleX(-1);

          .court-top {
            border: 3px solid ${(props) => props.theme.colors.green};
          }
          .court-middle {
            border-right: 3px solid ${(props) => props.theme.colors.green};
            border-left: 3px solid ${(props) => props.theme.colors.green};

            .left {
              border-right: 3px solid ${(props) => props.theme.colors.green};

              .text-wrap {
                transform: scaleX(-1);
              }
            }
            .right {
              .middle-top {
                transform: scaleX(-1);
                border-bottom: none;
              }

              .middle-bottom {
                border-bottom: 3px solid ${(props) => props.theme.colors.green};
                transform: scaleX(-1);
                order: -1;
                .icon-wrap {
                  .icon-circle {
                    background-color: ${(props) => props.theme.colors.green};
                  }
                }
              }
            }
          }
          .court-bottom {
            border: 3px solid ${(props) => props.theme.colors.green};
          }
        }
      }
    }

    .how-middle {
      display: flex;
      z-index: 1;
      padding: 1rem 4rem;

      .mini-court {
        flex: 0 0 50%;

        &:first-child {
          margin-right: 0.5rem;
        }
        &:last-child {
          margin-left: 0.5rem;
        }
      }

      .mini-court:first-child {
        .court-top {
          border: 3px solid ${(props) => props.theme.colors.green};
        }
        .court-middle {
          border-right: 3px solid ${(props) => props.theme.colors.green};
          border-left: 3px solid ${(props) => props.theme.colors.green};

          .left {
            border-right: 3px solid ${(props) => props.theme.colors.green};
          }
          .right {
            .middle-top {
              border-bottom: 3px solid ${(props) => props.theme.colors.green};
            }

            .middle-bottom {
              .icon-wrap {
                .icon-circle {
                  background-color: ${(props) => props.theme.colors.green};
                }
              }
            }
          }
        }
        .court-bottom {
          border: 3px solid ${(props) => props.theme.colors.green};
        }
      }

      @media only screen and (max-width: 850px) {
        padding: 2rem;
      }

      @media only screen and (max-width: 675px) {
        flex-direction: column;
        padding: 0 2rem;
      }

      @media only screen and (max-width: 675px) {
        padding-bottom: 2rem;
      }

      @media only screen and (max-width: 675px) {
        .mini-court:first-child {
          padding-bottom: 2rem;
          .court-top {
            border: 3px solid ${(props) => props.theme.colors.blue};
          }
          .court-middle {
            border-right: 3px solid ${(props) => props.theme.colors.blue};
            border-left: 3px solid ${(props) => props.theme.colors.blue};

            .left {
              border-right: 3px solid ${(props) => props.theme.colors.blue};
            }
            .right {
              .middle-top {
                border-bottom: 3px solid ${(props) => props.theme.colors.blue};
              }

              .middle-bottom {
                .icon-wrap {
                  .icon-circle {
                    background-color: ${(props) => props.theme.colors.blue};
                  }
                }
              }
            }
          }
          .court-bottom {
            border: 3px solid ${(props) => props.theme.colors.blue};
          }
        }
      }
    }

    .mini-court:nth-child(2) {
      .court-inner {
        transform: scaleX(-1);

        .court-middle {
          .left {
            .text-wrap {
              transform: scaleX(-1);
            }
          }
          .right {
            .middle-top {
              transform: scaleX(-1);
              border-bottom: none;
            }

            .middle-bottom {
              transform: scaleX(-1);
              order: -1;
              border-bottom: 3px solid ${(props) => props.theme.colors.blue};
            }
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .court-inner {
        height: 24rem;
      }
    }

    @media only screen and (max-width: 675px) {
      .how-top,
      .how-middle,
      .how-bottom {
        .mini-court {
          &:first-child {
            margin-right: 0;
          }
          &:last-child {
            margin-left: 0;
          }
        }
      }

      .mini-court:nth-child(2) {
        .court-inner {
          .court-top {
            border: 3px solid ${(props) => props.theme.colors.green};
          }
          .court-middle {
            border-right: 3px solid ${(props) => props.theme.colors.green};
            border-left: 3px solid ${(props) => props.theme.colors.green};

            .left {
              border-right: 3px solid ${(props) => props.theme.colors.green};
            }
            .right {
              .middle-bottom {
                border-bottom: 3px solid ${(props) => props.theme.colors.green};

                .icon-wrap {
                  .icon-circle {
                    background-color: ${(props) => props.theme.colors.green};
                  }
                }
              }
            }
          }
          .court-bottom {
            border: 3px solid ${(props) => props.theme.colors.green};
          }
        }
      }
    }

    @media only screen and (max-width: 550px) {
      width: 100%;

      .court-inner {
        height: 23rem;
      }
    }

    .how-bottom {
      display: flex;
      z-index: 1;
      padding: 1rem 4rem;

      @media only screen and (max-width: 850px) {
        padding: 2rem;
      }

      @media only screen and (max-width: 675px) {
        flex-direction: column;
        padding: 0 2rem;
      }

      .mini-court {
        flex: 0 0 50%;
        margin-right: 0.5rem;
      }
      @media only screen and (max-width: 675px) {
        padding-bottom: 2rem;
      }

      .notice-wrapper {
        flex: 0 0 50%;
        color: ${(props) => props.theme.colors.black};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        z-index: 1;
        margin-left: 0.5rem;

        .notice-inner {
          height: 28rem;
        }

        .notice-body-wrap {
          height: 100%;
          width: 100%;
          background-color: ${(props) => props.theme.colors.white};
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: relative;

          &:before {
            content: "Season Info";
            color: ${(props) => props.theme.colors.red};
            font-size: 1.2rem;
            background-color: ${(props) => props.theme.colors.white};
            border: 2px solid ${(props) => props.theme.colors.red};
            box-shadow: 5px 5px 0 ${(props) => props.theme.colors.red};
            text-align: center;
            position: absolute;
            top: 5px;
            left: 12%;
            width: 65%;
            padding: 1rem;

            @media only screen and (max-width: 500px) {
              left: 13%;
            }
          }

          &:after {
            content: "";
            background-image: url(${koalaGreen});
            background-repeat: no-repeat;
            background-size: contain;
            bottom: -27px;
            left: -20px;
            height: 30%;
            width: 18%;
            position: absolute;
            transform: scaleX(-1);
          }

          .notice-body-inner {
            border: 2px dashed ${(props) => props.theme.colors.red};
            height: 85%;
            width: 90%;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 2rem;

            .season-text-wrap {
              margin-bottom: 2rem;
              text-align: center;

              > h3 {
                font-size: 1.5rem;
              }
            }
            .info {
              margin-bottom: 1rem;
              width: 100%;
              display: flex;
              justify-content: space-around;

              .title {
                font-size: 1.2rem;
              }
              .season {
                color: ${(props) => props.theme.colors.red};
                text-decoration: underline;
              }
              .date {
                color: ${(props) => props.theme.colors.red};

                .wave {
                  transform: rotate(90deg);
                  width: 100%;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
`
