import styled from "styled-components"

export const FaqWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 4rem;

  .faq-inner {
    width: 80%;
    background: ${(props) => props.theme.colors.yellow};
    padding: 4rem 0;
    border-radius: 10px;

    .tabs-wrapper {
      display: flex;
      justify-content: space-around;

      .tab {
        padding: 1rem;
        transition: all 0.2s ease-in-out;

        &:after {
          display: block;
          content: "";
          border-bottom: 2px solid ${(props) => props.theme.colors.red};
          transform: scaleX(0);
          transition: transform 250ms ease-in-out;
          transform-origin: 100% 50%;
        }

        &:hover {
          cursor: pointer;
          &:after {
            transform: scaleX(1);
            transform-origin: 0 50%;
          }
        }

        h3 {
          font-size: 1.8rem;
          transition: all 0.2s ease-in-out;
          pointer-events: none;
        }
        .current-tab {
          > h3 {
            color: ${(props) => props.theme.colors.red};
            border-bottom: 2px solid ${(props) => props.theme.colors.red};
          }
        }
      }
    }

    .body-wrap {
      &:not(:first-child) {
        display: none;
      }

      .accordion {
        padding: 0 4rem;
        margin: 1rem 0;

        .question-wrap {
          border-bottom: 2px dashed ${(props) => props.theme.colors.red};
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: all 0.2s ease-in-out;

          &:hover {
            cursor: pointer;
            > p {
              color: ${(props) => props.theme.colors.red};
            }
          }

          > p {
            line-height: 2.2;
            transition: all 0.2s ease-in-out;
            pointer-events: none;
            flex: 0 0 90%;
          }

          .arrow-wrap {
            flex: 0 0 10%;
            display: flex;
            justify-content: center;
            align-items: center;

            .arrow {
              background-color: ${(props) => props.theme.colors.red};
              border-radius: 50%;
              width: 30px;
              height: 30px;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 1.8rem;
              font-weight: 600;
              color: ${(props) => props.theme.colors.yellow};
              pointer-events: none;

              @media only screen and (max-width: 900px) {
                width: 25px;
                height: 25px;
              }
            }
          }
        }
        .answer-wrap {
          margin-top: 1rem;
          display: none;

          .answer {
            font-size: 1.2rem;
            margin-bottom: 4rem;
          }
        }
      }
    }

    @media only screen and (max-width: 550px) {
      width: 95%;

      .tabs-wrapper .tab h3 {
        font-size: 1.2rem;
        font-weight: 800;
      }
      .body-wrap .accordion {
        padding: 0 1.5rem;
      }
    }
  }
`
