import styled from "styled-components"

export const LeaguesModal = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;

  .extra-wrapper {
    @media only screen and (max-width: 870px) {
      width: 95%;
    }

    .box-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: ${(props) => props.theme.colors.white};
      width: 60vw;
      padding: 2rem;
      margin-top: 4rem;

      .dash-box {
        border: 2px dashed ${(props) => props.theme.colors.green};
        width: 85%;
        padding: 3rem;

        .box-body {
          padding: 1rem 0;

          > h2 {
            color: ${(props) => props.theme.colors.green};
            text-align: center;
            font-size: 2rem;
            margin-bottom: 1rem;
          }
          ol {
            padding: 1rem 1rem 1rem 2rem;

            li {
              font-size: 1.2rem;
            }
          }
        }
      }

      @media only screen and (max-width: 870px) {
        width: 90%;
        max-width: 100%;
        margin: 4rem auto;
      }
      @media only screen and (max-width: 560px) {
        padding: 1rem;

        .dash-box {
          width: 100%;
          padding: 4rem 1rem 1rem;
        }
      }
    }
  }
`
