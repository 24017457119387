import React, { Fragment, Component } from "react"
import PanelFooter from "../PanelFooter"
import PanelHeader from "../PanelHeader"
import { LeaguesInner, Img, SecImg } from "./style"
import { LinkForBtn, Button } from "../Universal/style"
import typeKoalas from "../../resources/images/type-koalas.png"
import skillKoalas from "../../resources/images/skill-koalas.png"
import locationKoalas from "../../resources/images/location-koalas.png"
import ausMap from "../../resources/images/australia-map.png"
import { ynMaster } from "../../resources/index.js"
import { LeaguesModal } from "./modal-style"

class Leagues extends Component {
  constructor() {
    super()
    this.state = {
      currentIndex: 0,
      initialLoad: true,
      showLightbox: false,
      showQuestion: false,
      showMap: false,
    }
  }

  handleStart = () => {
    this.setState({ initialLoad: false })
  }

  handleAnswer = (answerIndex) => {
    this.setState({ currentIndex: answerIndex })
  }

  startAgain = () => {
    this.setState({ currentIndex: 0 })
  }

  //Doubles modal
  showModal = () => {
    this.setState({ showLightbox: true })
  }

  closeModal = () => {
    this.setState({ showLightbox: false })
  }

  //YesNo Modal
  showYesNo = () => {
    this.setState({ showQuestion: true })
  }

  closeYesNo = () => {
    this.setState({ showQuestion: false })
  }

  //map Modal
  showAuMap = () => {
    this.setState({ showMap: true })
  }

  closeAuMap = () => {
    this.setState({ showMap: false })
  }

  render() {
    const { currentIndex, initialLoad, showLightbox, showQuestion, showMap } = this.state
    const { yesNoQuestionsArray } = ynMaster
    let questionToShow = yesNoQuestionsArray[currentIndex]
    const { answer, description } = questionToShow

    let revealOutcome = false
    let outcomeHeading = ""
    let outcomeDescription = ""

    if (answer) {
      revealOutcome = true
      outcomeHeading = answer
      outcomeDescription = description
    }

    return (
      <div id="leagues-section">
        <PanelHeader
          leagues={true}
          panelTitle="Leagues"
          panelHeadingText="FTL Leagues are based on Types, Skill Levels and Locations"
          panelSubText="See below for the details of each category to find your suitable league!"
        />
        <LeaguesInner>
          <div className="box">
            <div className="items-wrapper">
              <div className="left global-box">
                <div className="upper">
                  <div className="image-wrap">
                    <SecImg alt="type-koalas" src={typeKoalas} />
                  </div>
                  <div className="heading-wrap">
                    <h3>Singles or Doubles</h3>
                  </div>
                  <div className="body-text-wrap">
                    <p>
                      We have <span className="singles-span">Mixed Singles</span> and <span className="doubles-span">Mixed Doubles</span> leagues available.
                    </p>
                    <p>For Doubles, please check below for the registration process details as it defers slightly from the Singles!</p>
                  </div>
                </div>

                {/* For the button */}
                <div className="dbl-btn" onClick={() => this.showModal()}>
                  <p className="dbl-btn-text">Check Doubles Details</p>
                </div>

                {showLightbox && (
                  <LeaguesModal>
                    <div className="extra-wrapper">
                      <div className="box-wrapper color-box">
                        <div className="close-wrap close-button" onClick={() => this.closeModal()}>
                          <i className="custom-icon icon-cancel">&#xe801;</i>
                        </div>
                        <div className="dash-box">
                          <div className="box-body">
                            <h2>Registering for Doubles</h2>
                            <ol>
                              <li>One team member will need to register their team in a league.</li>
                              <li>Ensure to use the correct partner email when registering.</li>
                              <li>Ensure your partner accepts the team invitation email to complete the registration process.</li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </LeaguesModal>
                )}
              </div>

              <div className="middle global-box">
                <div className="upper">
                  <div className="image-wrap">
                    <SecImg alt="skill-koalas" src={skillKoalas} />
                  </div>
                  <div className="heading-wrap">
                    <h3>3 Different Levels</h3>
                  </div>
                  <div className="body-text-wrap">
                    <p>
                      We have 3 different skill level available in each regional league; <span className="skill-span">Newbie</span>,{" "}
                      <span className="skill-span">Fair</span> and <span className="skill-span">Ace</span>.
                    </p>
                    <p>If you are unsure which level you want to play for, please try out our skill level check questionnaire.</p>
                  </div>
                </div>

                {/* For the button */}
                <div className="yes-no-modal" onClick={() => this.showYesNo()}>
                  <p className="ynModal-btn-text">Check Your Skill Level</p>
                </div>

                {showQuestion && (
                  <div className="box-sec-wrapper ynModal-wrap">
                    {initialLoad ? (
                      <LeaguesModal className="box-sec-wrapper">
                        <div className="extra-wrapper">
                          <div className="box-wrapper color-box">
                            <div className="close-wrap close-button" onClick={() => this.closeYesNo()}>
                              <i class="custom-icon icon-cancel">&#xe801;</i>
                            </div>
                            <div className="dash-box">
                              <div className="box-body">
                                <h2>Skill Level Check Questionnaire</h2>
                                <div id="yn-start" onClick={() => this.handleStart()}>
                                  <p>Start</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </LeaguesModal>
                    ) : (
                      <Fragment>
                        {revealOutcome ? (
                          <LeaguesModal className="box-sec-wrapper">
                            <div className="extra-wrapper">
                              <div className="box-wrapper color-box">
                                <div className="close-wrap close-button" onClick={() => this.closeYesNo()}>
                                  <i class="custom-icon icon-cancel">&#xe801;</i>
                                </div>
                                <div className="dash-box">
                                  <div id="answer-box" className="box-body">
                                    <p className="top-text">You may want to try out...</p>
                                    <h3 className="answer-league">{outcomeHeading}</h3>
                                    <p className="description">{outcomeDescription}</p>
                                    <div className="final-btns-wrap">
                                      <div id="again-btn" onClick={() => this.startAgain()}>
                                        <p>Try Again</p>
                                      </div>

                                      <LinkForBtn href={process.env.APP_URL} target="_blank" rel="noopener noreferrer">
                                        <Button>Sign Up Now!</Button>
                                      </LinkForBtn>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </LeaguesModal>
                        ) : (
                          <LeaguesModal className="box-sec-wrapper">
                            <div className="extra-wrapper">
                              <div className="box-wrapper color-box">
                                <div className="close-wrap close-button" onClick={() => this.closeYesNo()}>
                                  <i class="custom-icon icon-cancel">&#xe801;</i>
                                </div>
                                <div className="dash-box">
                                  <div className="box-body">
                                    <p className="question">{questionToShow.question}</p>
                                    <div className="btns-wrap">
                                      <div className="yn-btn yes-btn" onClick={() => this.handleAnswer(questionToShow.yes)}>
                                        <p>Yes</p>
                                      </div>
                                      <div className="yn-btn no-btn" onClick={() => this.handleAnswer(questionToShow.no)}>
                                        <p>No</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </LeaguesModal>
                        )}
                      </Fragment>
                    )}
                  </div>
                )}
              </div>

              <div id="leagues-map-section" className="right global-box">
                <div className="upper">
                  <div className="image-wrap">
                    <SecImg alt="location-koalas" src={locationKoalas} />
                  </div>
                  <div className="heading-wrap">
                    <h3>5 Different States</h3>
                  </div>
                  <div className="body-text-wrap">
                    <p>We have leagues in over 20 different locations across 5 states in Australia!</p>
                    <p>If you are unsure which regional leagues are closest to you, please refer to our leagues map for more details.</p>
                  </div>
                </div>

                <div className="mapModal-btn" onClick={() => this.showAuMap()}>
                  <p className="mapModal-btn-text">Check Regional Leagues</p>
                </div>

                {showMap && (
                  <LeaguesModal className="map-modal">
                    <div className="extra-wrapper">
                      <div className="box-wrapper map-modal-inner">
                        <div className="box-wrapper-inner">
                          <div className="close-wrap close-button" onClick={() => this.closeAuMap()}>
                            <i class="custom-icon icon-cancel">&#xe801;</i>
                          </div>
                          <div className="australia-map">
                            <Img alt="australian-map" src={ausMap} />
                          </div>
                          <div className="right-states">
                            <div className="state-lists">
                              <div className="states-ul">
                                <div className="state-wrap">
                                  <p className="state-inner">QLD</p>
                                  <ul className="leagues-ul">
                                    <li>Cairns</li>
                                    <li>North Brisbane</li>
                                    <li>South Brisbane</li>
                                    <li>Gold Coast</li>
                                  </ul>
                                </div>
                                <div className="state-wrap">
                                  <p className="state-inner">NSW</p>
                                  <ul className="leagues-ul">
                                    <li>Newcastle</li>
                                    <li>Central Sydney</li>
                                    <li>North Shore Sydney</li>
                                    <li>Northern Beaches Sydney</li>
                                    <li>Inner West Sydney</li>
                                    <li>Parramatta Sydney</li>
                                    <li>Eastern Suburbs Sydney</li>
                                    <li>Sutherland Shire Sydney</li>
                                    <li>Canberra</li>
                                  </ul>
                                </div>
                                <div className="state-wrap">
                                  <p className="state-inner">VIC</p>
                                  <ul className="leagues-ul">
                                    <li>Inner City Melbourne</li>
                                    <li>North Melbourne</li>
                                    <li>West Melbourne</li>
                                    <li>East Melbourne</li>
                                    <li>South East Melbourne</li>
                                    <li>Geelong</li>
                                  </ul>
                                </div>
                                <div className="state-wrap sawa">
                                  <p className="state-inner">SA</p>
                                  <ul className="leagues-ul">
                                    <li>West Adelaide</li>
                                    <li>East Adelaide</li>
                                  </ul>
                                  <div className="state-wa">
                                    <p className="state-inside">WA</p>
                                    <ul className="leagues-ul">
                                      <li>North Perth</li>
                                      <li>South Perth</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </LeaguesModal>
                )}
              </div>
            </div>
          </div>
        </LeaguesInner>

        <PanelFooter panelFooterText="Found your suitable league?" />
      </div>
    )
  }
}

export default Leagues
