import React from "react"
import styled from "styled-components"

const PanelTopWrap = styled.div`
  margin-bottom: ${(props) => (props.leagues ? "8rem" : "4rem")};

  .panel-inner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .top-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;

      .header-box-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4rem;

        .header-text-box {
          background-color: ${(props) => props.theme.colors.white};
          border: 2px solid ${(props) => props.theme.colors.green};
          box-shadow: 8px 8px 0 ${(props) => props.theme.colors.green};
          text-align: center;
          padding: 0.5rem 5rem;
        }
        > h1 {
          color: ${(props) => props.theme.colors.green};
          font-size: 1.6rem;
        }
      }
      .vertical-bar {
        border-left: 2px solid ${(props) => props.theme.colors.green};
        height: 5rem;
      }
      .horizontal-bar {
        border-top: 2px solid ${(props) => props.theme.colors.green};
        width: 80%;
      }
    }
    .section-text-wrap {
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;

      > h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
      }
      p {
        text-decoration: underline;
      }
    }
  }

  @media only screen and (max-width: 1260px) {
    .section-text-wrap {
      h2 {
        text-align: center;
      }
    }
  }
  @media only screen and (max-width: 970px) {
    .section-text-wrap {
      p {
        text-align: center;
      }
    }
  }
`

const PanelHeader = (props) => {
  return (
    <PanelTopWrap leagues={props.leagues}>
      <div className="panel-inner">
        <div className="top-wrap">
          <div className="header-box-wrapper">
            <h1 className="header-text-box">{props.panelTitle}</h1>
          </div>
          <div className="vertical-bar"></div>
          <div className="horizontal-bar"></div>
        </div>
        <div className="section-text-wrap">
          <h2>{props.panelHeadingText}</h2>
          <p>{props.panelSubText}</p>
        </div>
      </div>
    </PanelTopWrap>
  )
}

export default PanelHeader
